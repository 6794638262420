import React, { useEffect } from "react";
import Layout from "fragments/layout/layout";
import { IconButton, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { COLORS } from "constants/colors";

import { useModalsContext } from "contexts/modals";
import { getSubscriptionPackages } from "apis";
import { useToastsContext } from "contexts/toasts";
import Loader from "components/loader";
import ShowBool from "components/showBool";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AdjustIcon from "@mui/icons-material/Adjust";

// redux
import { setPackages } from "store/subscriptionPackagesSlice";
import { useDispatch, useSelector } from "react-redux";

export default function SubscriptionPackages() {
  const dispatch = useDispatch();
  const { packages } = useSelector((state) => state.subscriptionPackages);
  const { toggleToast } = useToastsContext();
  const {
    toggleAddSubscriptionModal,
    toggleUpdateSubscriptionModal,
    toggleDeleteSubscriptionModal,
    toggleChangeSubscriptionStatusModal,
  } = useModalsContext();

  // User Table
  const columnsForPackages = [
    {
      name: "",
      label: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, update) => {
          let rowIndex = Number(tableMeta.rowIndex) + 1;
          return <span>{rowIndex}</span>;
        },
      },
    },
    {
      name: "package_name",
      label: "Package name",
      options: {
        customBodyRender: (name) => {
          return <Typography>{name}</Typography>;
        },
      },
    },
    {
      name: "storeId",
      label: "Store Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "days",
      label: "Days",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "super_swipe",
      label: "Super Swipe",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} />;
        },
      },
    },
    {
      name: "advance_filters",
      label: "Advance Filters",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} />;
        },
      },
    },
    {
      name: "backtrack",
      label: "Backtrack",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} />;
        },
      },
    },
    {
      name: "unlimited_right_zzwipes",
      label: "Unlimited zzwipes",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} />;
        },
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} type="status" />;
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (id) => {
          return (
            <div className="flex">
              <IconButton
                onClick={() => toggleUpdateSubscriptionModal(id)}
                sx={{ color: COLORS.primary }}>
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => toggleDeleteSubscriptionModal(id)}
                sx={{ color: COLORS.red }}>
                <DeleteOutlineIcon />
              </IconButton>
              <IconButton
                onClick={() => toggleChangeSubscriptionStatusModal(id)}>
                <AdjustIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const optionsForPackages = {
    onRowClick: (item) => {
      // console.log(user);
      // const id = user[1];
      // navigate(`/users/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [10, 50, 100],
    elevation: 2,
    filter: false,
  };

  const getPackages = async () => {
    await getSubscriptionPackages()
      .then((res) => {
        if (packages) {
          dispatch(setPackages([...res.data.result, ...packages]));
        } else {
          dispatch(setPackages(res.data.result));
        }
      })
      .catch((err) => {
        console.log(err);
        toggleToast({
          type: "error",
          msg: "Error fetching data.",
        });
      });
  };

  useEffect(() => {
    if (!packages?.length > 0) {
      getPackages();
    }
  }, []);

  return (
    <Layout title="Subscription Packages">
      <div className="m-5">
        {packages ? (
          <>
            <div className="flex justify-between">
              <Typography variant="h5">Subscription Packages</Typography>
              <IconButton
                sx={{ color: COLORS.primary, fontSize: "1.1rem" }}
                onClick={() => toggleAddSubscriptionModal()}>
                <ControlPointIcon sx={{ fontSize: "2rem" }} />
              </IconButton>
            </div>

            <div className="h-400 mt-5">
              {packages && (
                <MUIDataTable
                  title={"Subsciption Packages"}
                  data={packages}
                  columns={columnsForPackages}
                  options={optionsForPackages}
                />
              )}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Layout>
  );
}
