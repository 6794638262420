import React, { useState, useEffect } from "react";
import { COLORS } from "constants/colors";
import { useToastsContext } from "contexts/toasts";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { changeUserStatus, updateSubscriptionPackageStatus } from "apis";
import { updatePackage } from "store/subscriptionPackagesSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/index";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { updateUser } from "store/userSlice";

export default function ChangeUserStatus({
  open,
  handleClose,
  payload: id,
}) {
  const dispatch = useDispatch();
  const { verified, unverified } = useSelector((state) => state.users);
  const { toggleToast } = useToastsContext();
  const [_open, set_open] = useState(false);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState();

  const handleChangeUserStatus = async () => {
    setLoading(true);
    let ban = user.is_banned;

    if(ban == 0) {
      ban = 1
    } else if (ban == 1) {
      ban = 0
    }

    await changeUserStatus({ userId: user.userId, is_banned: ban })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status === "fail") {
          toggleToast({
            type: "warning",
            msg: res?.data?.message,
          });
        } else {
          dispatch(updateUser({...user, is_banned: ban}));
          setUser({...user, is_banned: ban})
          toggleToast({
            type: "success",
            msg: `Successfully ${ban == 0 ? "Ban" : "Unban"} subscription package.`,
          });
          
          set_open(false);
          setUser({});
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
        toggleToast({
          type: "error",
          msg: err.response.data.message || `Error ${user.is_banned == 0 ? "banning" : "unbanning"} "${user.first_name}".`,
        });
      });
  
    
    setLoading(false);
  };
  
  // console.log(user);

  useEffect(() => {
    if (id) {
      if(verified && !unverified) {
        const itemss = verified.filter((item) => item.userId === id);
        setUser(itemss[0]);
      } else if (!verified && unverified) {
        const itemss = verified.filter((item) => item.userId === id);
        setUser(itemss[0]);
      } else if (verified && unverified) {
        const itemss = [...verified, ...unverified].filter((item) => item.userId === id);
        setUser(itemss[0]);
      }
    }
  }, [id]);

  useEffect(() => {
    set_open(open);
  }, [open]);

  return (
    <>
      {open && (
        <Dialog
          open={_open}
          onClose={() => handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          {user ? (
            <>
              <DialogContent sx={{ padding: "1rem", textAlign: "center" }}>
                <div className="min-w-[250px] sm:w-[400px] flex flex-col gap-5">
                  <div className="">
                    <WarningAmberIcon
                      sx={{ fontSize: "3rem", color: COLORS.danger }}
                    />
                  </div>
                  <Typography variant="h4">Are you sure?</Typography>

                  <Typography sx={{ opacity: "0.7" }}>
                    {`Are you Sure to ${user.is_banned == 1 ? "unBan": "Ban"} "${user.first_name}"!`}
                  </Typography>
                </div>
              </DialogContent>
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  onClick={() => handleClose()}
                  size="large">
                  Cancel
                </Button>
                <LoadingButton
                  size="large"
                  sx={{ margin: "8px" }}
                  variant="contained"
                  loading={loading}
                  onClick={() => handleChangeUserStatus()}>
                  {user.is_banned == 1 ? "unBan": "Ban"}
                </LoadingButton>
              </DialogActions>
            </>
          ) : (
            <Loader />
          )}
        </Dialog>
      )}
    </>
  );
}


    // if(spackage.active == 0) {
    //   setSPackage({...spackage, active: 1})
    //   dispatch(updatePackage({...spackage, active: 1}));
    // } else if (spackage.active == 1) {
    //   setSPackage({...spackage, active: 0})
    //   dispatch(updatePackage({...spackage, active: 0}));
    // }