import React, { useState, useEffect } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export default function ImageZoomModal({ open, handleClose, payload }) {
  const [_open, set_open] = useState(false);

  useEffect(() => {
    set_open(open);
  }, [open]);

  return (
    <>
      {open && (
        <Dialog
          open={_open}
          onClose={() => handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{payload.title}</DialogTitle>
          <DialogContent>
            <div>
              <img
                src={payload.img}
                alt={payload.title}
                style={{ maxWidth: "300px" }}
              />
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}
    </>
  );
}
