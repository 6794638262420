import React, { useState, useEffect } from "react";
import { Typography, Avatar, IconButton,  } from "@mui/material";
import MUIDataTable from "mui-datatables";
import Loader from "components/loader";
import { getUnverifiedUsers, verifyUser } from "apis";
import { useToastsContext } from "contexts/toasts";
import { useModalsContext } from "contexts/modals";
import { convertDateTime } from "utils";
import { Link } from "react-router-dom";
import { COLORS } from "constants/colors";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AdjustIcon from "@mui/icons-material/Adjust";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "store/userSlice";

export default function UnverifiedUsers() {
  const dispatch = useDispatch();
  const { toggleImageZoomModal, toggleDeleteUserModal, toggleVerifyUserModal } =
  useModalsContext();
  const { toggleToast } = useToastsContext();
  const { unverified } = useSelector((state) => state.users);

  const [unverifiedUsers, setUnverifiedUsers] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const columnsForUnverifiedUsers = [
    {
      name: "",
      label: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, update) => {
          let rowIndex = Number(tableMeta.rowIndex) + 1;
          return <span>{rowIndex}</span>;
        },
      },
    },
    {
      name: "image",
      label: "User's Profile Image",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (image) => {
          return (
            <Avatar
              onClick={(image) =>
                toggleImageZoomModal({
                  modalTitle: "User's Profile Image",
                  img: image.target.currentSrc,
                })
              }
              variant="rounded"
              src={image}
              sx={{ width: "3rem", height: "3rem", cursor: "pointer" }}
            />
          );
        },
      },
    },
    {
      name: "verification_image",
      label: "User's Camera Image",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (image) => {
          return (
            <Avatar
              onClick={(image) =>
                toggleImageZoomModal({
                  modalTitle: "User's Camera Image",
                  img: image.target.currentSrc,
                })
              }
              variant="rounded"
              src={image}
              sx={{ width: "3rem", height: "3rem", cursor: "pointer" }}
            />
          );
        },
      },
    },
    {
      name: "first_name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "phone",
      label: "User's Phone Number",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "created_at",
      label: "Registered At",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (dateTime) => {
          return <Typography>{convertDateTime(dateTime)}</Typography>;
        },
      },
    },

    {
      name: "gender",
      label: "Gender",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (userId) => {
          return (
            <div className="flex">
              <Link to={`/users/${userId}`}>
                <IconButton>
                  <VisibilityIcon sx={{ color: COLORS.primary }} />
                </IconButton>
              </Link>
              <IconButton
                onClick={() => toggleDeleteUserModal(userId)}
                sx={{ color: COLORS.red }}>
                <DeleteOutlineIcon />
              </IconButton>
              <IconButton onClick={() => toggleVerifyUserModal(userId)}>
                <AdjustIcon sx={{ color: COLORS.primary }} />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const optionsForUnverifiedUsers = {
    onRowClick: (user) => {
      // console.log(user);
      // const id = user[1];
      // navigate(`/users/${id}`);
    },
    onChangePage: (pageNumber) => {
      if ((pageNumber + 1) * 50 + 20 > unverifiedUsers.length && hasMore) {
        console.log("called");
        setUnverifiedUsers([...unverifiedUsers]);
      } else {
        console.log("not call");
      }
    },
    selectableRowsHideCheckboxes: true,
    // selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [50],
    elevation: 2,
    filter: false,
    rowsPerPage: 50,
  };

  const get_unverified_users = async (page) => {
    await getUnverifiedUsers(page)
      .then((res) => {
        console.log(res);
        if (unverified && unverified.length > 0) {
          dispatch(setUsers({type: 'unverified', users: [...unverified, ...res.data.result]}));
        } else {
          dispatch(setUsers({type: 'unverified', users: res.data.result}));
        }
        if (res.data.result.length < 50) {
          setHasMore(false);
        }
      })
      .catch((err) => {
        toggleToast({
          msg: err.response.data.message,
          type: "error",
        });
      });
  };

  console.log(unverifiedUsers)

  useEffect(() => {
    if(!unverified?.length > 0) {
      get_unverified_users(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-400 mt-5">
      {unverified ? (
        <MUIDataTable
          title={"Unverified Users"}
          data={unverified}
          columns={columnsForUnverifiedUsers}
          options={optionsForUnverifiedUsers}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
}
