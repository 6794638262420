import React from "react";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { COLORS } from "constants/colors";
import { Typography } from "@mui/material";

export default function NoResult({text}) {
  return (
    <div className="w-[100%] flex flex-col items-center h-[80%] max-h-[500px] justify-center">
      <SearchOffIcon sx={{color: COLORS.yellow, fontSize: '4rem'}} />
      <Typography variant="h6" sx={{opacity: '0.5'}}>
        {text}
      </Typography>
    </div>
  );
}
