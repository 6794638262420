import React, { useState } from "react";

import AddSubscriptionPackage from "components/modals/subscriptionPackage/add";
import ImageZoomModal from "components/modals/imageZoom";
import UpdateSubscriptionPackage from "components/modals/subscriptionPackage/update";
import DeleteSubscriptionPackage from "components/modals/subscriptionPackage/delete";
import ChangeSubscriptionPackageStatus from "components/modals/subscriptionPackage/changeStatus";
import ChangeUserStatus from "components/modals/users/changeStatus";
import DeleteUser from "components/modals/users/delete";
import VerifyUserModal from "components/modals/users/verify";

const ModalsContext = React.createContext();

/**
 * This hook can only be used by components under the `ModalsContext` component. Otherwise it will throw.
 */
export const useModalsContext = () => {
  const context = React.useContext(ModalsContext);

  if (!context) {
    throw new Error("Component rendered outside the provider tree");
  }

  return context;
};

/**
 * Component used to render components that depend on Modals being available. These components can then
 * `useModalsContext` safely to get stuff without having to null check it.
 */
export const Modals = (props) => {
  const [modal, setModal] = useState(false);

  const toggleModal = (arg) => {
    if (!modal) {
      setModal(arg);
    } else {
      setModal({});
    }
  };

  // Subscription Package Modals
  const toggleAddSubscriptionModal = () => {
    if (!modal) {
      setModal("ADD_SUBSCRIPTION_PACKAGE");
    } else {
      setModal(false);
    }
  };

  const toggleUpdateSubscriptionModal = (payload) => {
    if (!modal) {
      setModal({ type: "UPDATE_SUBSCRIPTION_PACKAGE", payload });
    } else {
      setModal(false);
    }
  };

  const toggleDeleteSubscriptionModal = (payload) => {
    if (!modal) {
      setModal({ type: "DELETE_SUBSCRIPTION_PACKAGE", payload });
    } else {
      setModal(false);
    }
  };

  const toggleChangeSubscriptionStatusModal = (payload) => {
    if (!modal) {
      setModal({ type: "CHANGE_SUBSCRIPTION_PACKAGE_STATUS", payload });
    } else {
      setModal(false);
    }
  };

  // User Modals
  const toggleUpdateUserModal = (payload) => {
    if (!modal) {
      setModal({ type: "UPDATE_USER", payload });
    } else {
      setModal(false);
    }
  };

  const toggleDeleteUserModal = (payload) => {
    if (!modal) {
      setModal({ type: "DELETE_USER", payload });
    } else {
      setModal(false);
    }
  };

  const toggleVerifyUserModal = (payload) => {
    if (!modal) {
      setModal({ type: "VERIFY_USER", payload });
    } else {
      setModal(false);
    }
  };

  // Image Zoom Modal
  const toggleImageZoomModal = (payload) => {
    if (!modal) {
      setModal({
        type: "ZOOM_IMAGE_MODAL",
        payload: { title: payload.modalTitle, img: payload.img },
      });
    } else {
      setModal(false);
    }
  };

  return (
    <ModalsContext.Provider
      value={{
        toggleModal,
        toggleAddSubscriptionModal,
        toggleImageZoomModal,
        toggleUpdateSubscriptionModal,
        toggleDeleteSubscriptionModal,
        toggleChangeSubscriptionStatusModal,
        toggleUpdateUserModal,
        toggleDeleteUserModal,
        toggleVerifyUserModal
      }}>
      {modal && (
        <>
          {/* Subscription Package Modals */}
          {modal === "ADD_SUBSCRIPTION_PACKAGE" && (
            <AddSubscriptionPackage
              open={modal ? true : false}
              handleClose={() => toggleAddSubscriptionModal()}
            />
          )}
          {modal?.type === "UPDATE_SUBSCRIPTION_PACKAGE" && (
            <UpdateSubscriptionPackage
              open={modal ? true : false}
              payload={modal.payload}
              handleClose={() => toggleUpdateSubscriptionModal()}
            />
          )}
          {modal?.type === "DELETE_SUBSCRIPTION_PACKAGE" && (
            <DeleteSubscriptionPackage
              open={modal ? true : false}
              payload={modal.payload}
              handleClose={() => toggleDeleteSubscriptionModal()}
            />
          )}
          {modal?.type === "CHANGE_SUBSCRIPTION_PACKAGE_STATUS" && (
            <ChangeSubscriptionPackageStatus
              open={modal ? true : false}
              payload={modal.payload}
              handleClose={() => toggleChangeSubscriptionStatusModal()}
            />
          )}

          {/* User Modals */}
          {modal?.type === "UPDATE_USER" && (
            <ChangeUserStatus
              open={modal ? true : false}
              payload={modal.payload}
              handleClose={() => toggleUpdateUserModal()}
            />
          )}
          {modal?.type === "DELETE_USER" && (
            <DeleteUser
              open={modal ? true : false}
              payload={modal.payload}
              handleClose={() => toggleDeleteUserModal()}
            />
          )}
          {modal?.type === "VERIFY_USER" && (
            <VerifyUserModal
              open={modal ? true : false}
              payload={modal.payload}
              handleClose={() => toggleVerifyUserModal()}
            />
          )}

          {/* Image Zoom Modals */}
          {modal?.type === "ZOOM_IMAGE_MODAL" && (
            <ImageZoomModal
              open={modal ? true : false}
              handleClose={() => toggleAddSubscriptionModal()}
              payload={modal.payload}
            />
          )}
        </>
      )}
      {props.children}
    </ModalsContext.Provider>
  );
};
