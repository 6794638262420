import React, { useState, useEffect } from "react";
import { COLORS } from "constants/colors";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useToastsContext } from "contexts/toasts";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { updateSubscriptionPackage } from "apis";
import { updatePackage } from "store/subscriptionPackagesSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/index";

export default function UpdateSubscriptionPackage({
  open,
  handleClose,
  payload: id,
}) {
  const dispatch = useDispatch();
  const { packages } = useSelector((state) => state.subscriptionPackages);
  const { toggleToast } = useToastsContext();
  const [_open, set_open] = useState(false);
  const [spackage, setSPackage] = useState();
  const [loading, setLoading] = useState();

  const handleUpdatePackage = async () => {
    setLoading(true);
    await updateSubscriptionPackage(spackage)
      .then((res) => {
        console.log(res);
        dispatch(updatePackage(spackage))
        toggleToast({
          type: "success",
          msg: "Successfully Updated Subscription Package.",
        });
        set_open(false);
        setSPackage({});
        handleClose()
      })
      .catch((err) => {
        console.log(err);
        toggleToast({
          type: "error",
          msg: "Error Updating Subscription Package.",
        });
      });

    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      const itemss = packages.filter((item) => item.id === id);
      setSPackage(itemss[0]);
    }
  }, [id]);

  useEffect(() => {
    set_open(open);
  }, [open]);

  // console.log(spackage);

  return (
    <>
      {open && (
        <Dialog
          open={_open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <div className="flex justify-between items-center mr-2 mt-2">
            <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
              Update Subscription Package
            </DialogTitle>
            <div className="">
              <IconButton
                disabled={loading}
                sx={{ color: COLORS.primary, fontSize: "1.1rem" }}
                onClick={() => handleClose()}>
                <HighlightOffIcon sx={{ fontSize: "2rem" }} />
              </IconButton>
            </div>
          </div>
          {spackage ? (
            <>
              <DialogContent sx={{ padding: "1rem" }}>
                <div className="min-w-[250px] sm:w-[400px] flex flex-col gap-5">
                  <TextField
                    label="Package Name"
                    value={spackage.package_name}
                    onChange={(e) =>
                      setSPackage({ ...spackage, package_name: e.target.value })
                    }
                    fullWidth
                  />
                  <TextField
                    label="Store Id"
                    value={spackage.storeId}
                    onChange={(e) =>
                      setSPackage({ ...spackage, storeId: e.target.value })
                    }
                    fullWidth
                  />
                  <TextField
                    label="Days"
                    type="number"
                    value={spackage.days}
                    onChange={(e) =>
                      setSPackage({
                        ...spackage,
                        days: parseInt(e.target.value),
                      })
                    }
                    fullWidth
                  />
                  <TextField
                    label="Duration"
                    value={spackage.duration}
                    onChange={(e) =>
                      setSPackage({ ...spackage, duration: e.target.value })
                    }
                    fullWidth
                  />
                  <TextField
                    label="Package Description"
                    rows="3"
                    value={spackage.description}
                    onChange={(e) =>
                      setSPackage({ ...spackage, description: e.target.value })
                    }
                    multiline
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  sx={{margin: '8px'}}
                  variant="contained"
                  loading={loading}
                  onClick={() => handleUpdatePackage()}>
                  Update Package
                </LoadingButton>
              </DialogActions>
            </>
          ) : (
            <Loader />
          )}
        </Dialog>
      )}
    </>
  );
}
