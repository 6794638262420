export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function convertDateTime(dateTime) {
  return `${dateTime.split("T")[0]} at ${
    dateTime.split("T")[1].split(":")[0]
  }:${dateTime.split("T")[1].split(":")[1]}`;
}

export function convertJsonToArray(json) {
  var result = [];
  for (let key in json) {
    if(key.length > 2 && json[key].length > 2) {
      result.push({question: key, answer: json[key]});
    }
  }
  return result;
}

export function UpperCaseFirstLetter(str) {
  if(str) {
    var temp = str.split("-"),
      i,
      pretty;
    for (i = 0; i < temp.length; i++) {
      temp[i] = temp[i].charAt(0).toUpperCase() + temp[i].slice(1);
    }
    pretty = temp.join(" ");
    return pretty;
  }
 
}