import React from "react";
import Layout from "fragments/layout/layout";
import { Typography } from "@mui/material";
import { COLORS } from "constants/colors.js";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

export default function Dashboard() {
  return (
    <Layout title="Dashboard">
      <div className="m-3">
        <Typography variant="h5">Dashboard</Typography>
        <div className="flex flex-col md:flex-row gap-4 mt-4">
          <div className="w-full bg-purple shadow-xl  flex flex-col gap-2 items-center justity-center py-6 md:py-14 rounded-2xl">
            <Typography variant="h4" sx={{ color: COLORS.white }}>
              (136)
            </Typography>
            <div className="flex gap-2 opacity-70">
              <PeopleAltIcon sx={{ color: COLORS.white }} />
              <Typography sx={{ color: COLORS.white, fontSize: "1.2rem" }}>
                Users
              </Typography>
            </div>
          </div>

          <div className="w-full bg-orange shadow-xl flex flex-col gap-2 items-center justity-center py-6 md:py-14 rounded-2xl">
            <Typography variant="h4" sx={{ color: COLORS.white }}>
              (136)
            </Typography>
            <div className="flex gap-2 opacity-70">
              <PeopleAltIcon sx={{ color: COLORS.white }} />
              <Typography sx={{ color: COLORS.white, fontSize: "1.2rem" }}>
                Users
              </Typography>
            </div>
          </div>

          <div className="w-full bg-info shadow-xl flex flex-col gap-2 items-center justity-center py-6 md:py-14 rounded-2xl">
            <Typography variant="h4" sx={{ color: COLORS.white }}>
              (136)
            </Typography>
            <div className="flex gap-2 opacity-70">
              <PeopleAltIcon sx={{ color: COLORS.white }} />
              <Typography sx={{ color: COLORS.white, fontSize: "1.2rem" }}>
                Users
              </Typography>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-3 mt-10 ">
          <div className="flex gap-3 p-5 shadow-lg  w-full rounded-2xl border border-gray_light">
            <div className="p-3 bg-gray_light rounded-lg opacity-80">
              <PeopleAltIcon sx={{ color: COLORS.indigo }} />
            </div>
            <div className="flex flex-col">
              <Typography sx={{ fontSize: "1.1rem", opacity: "0.7" }}>
                Chaperone Support Users
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>(6)</Typography>
            </div>
          </div>
          {/* ############## */}
          <div className="flex gap-3 p-5 shadow-lg  w-full rounded-2xl border border-gray_light">
            <div className="p-3 bg-gray_light rounded-lg opacity-80">
              <ConfirmationNumberIcon sx={{ color: COLORS.indigo }} />
            </div>
            <div className="flex flex-col">
              <Typography sx={{ fontSize: "1.1rem", opacity: "0.7" }}>
                Chaperone Support Tickets
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>(6)</Typography>
            </div>
          </div>
          {/* ############## */}
          <div className="flex gap-3 p-5 shadow-lg  w-full rounded-2xl border border-gray_light">
            <div className="p-3 bg-gray_light rounded-lg opacity-80">
              <PeopleAltIcon sx={{ color: COLORS.danger }} />
            </div>
            <div className="flex flex-col">
              <Typography sx={{ fontSize: "1.1rem", opacity: "0.7" }}>
                Latina Support Users
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>(6)</Typography>
            </div>
          </div>
          {/* ############## */}
          <div className="flex gap-3 p-5 shadow-lg  w-full rounded-2xl border border-gray_light">
            <div className="p-3 bg-gray_light rounded-lg opacity-80">
              <ConfirmationNumberIcon sx={{ color: COLORS.danger }} />
            </div>
            <div className="flex flex-col">
              <Typography sx={{ fontSize: "1.1rem", opacity: "0.7" }}>
                Latina Support Tickets
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>(6)</Typography>
            </div>
          </div>
          {/* ############## */}
          <div className="flex gap-3 p-5 shadow-lg  w-full rounded-2xl border border-gray_light">
            <div className="p-3 bg-gray_light rounded-lg opacity-80">
              <PeopleAltIcon sx={{ color: COLORS.info }} />
            </div>
            <div className="flex flex-col">
              <Typography sx={{ fontSize: "1.1rem", opacity: "0.7" }}>
                Revert Support Users
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>(6)</Typography>
            </div>
          </div>
          {/* ############## */}
          <div className="flex gap-3 p-5 shadow-lg  w-full rounded-2xl border border-gray_light">
            <div className="p-3 bg-gray_light rounded-lg opacity-80">
              <ConfirmationNumberIcon sx={{ color: COLORS.info }} />
            </div>
            <div className="flex flex-col">
              <Typography sx={{ fontSize: "1.1rem", opacity: "0.7" }}>
                Revert Support Tickets
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>(6)</Typography>
            </div>
          </div>
          {/* ############## */}
          <div className="flex gap-3 p-5 shadow-lg  w-full rounded-2xl border border-gray_light">
            <div className="p-3 bg-gray_light rounded-lg opacity-80">
              <PeopleAltIcon sx={{ color: COLORS.dark }} />
            </div>
            <div className="flex flex-col">
              <Typography sx={{ fontSize: "1.1rem", opacity: "0.7" }}>
                Ask an Expert Users
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>(6)</Typography>
            </div>
          </div>
          {/* ############## */}
          <div className="flex gap-3 p-5 shadow-lg  w-full rounded-2xl border border-gray_light">
            <div className="p-3 bg-gray_light rounded-lg opacity-80">
              <PeopleAltIcon sx={{ color: COLORS.dark }} />
            </div>
            <div className="flex flex-col">
              <Typography sx={{ fontSize: "1.1rem", opacity: "0.7" }}>
                Ask an Expert Tickets
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>(6)</Typography>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
