import React, { useState } from "react";
import Layout from "fragments/layout/layout";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import UnverifiedUsers from "./components/unverifiedUsers";
import VerifiedUsers from "./components/verifiedUsers";

export default function Users() {
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Layout title="Users">
      <div className="m-5">
        <div className="flex justify-between">
          <Typography variant="h5">Users</Typography>
        </div>
        <div className="flex justify-center">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="basic tabs example">
            <Tab label="Verified Users" {...a11yProps(0)} />
            <Tab label="Unverified Users" {...a11yProps(1)} />
          </Tabs>
        </div>
        <TabPanel value={tabValue} index={0}>
          <VerifiedUsers />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <UnverifiedUsers />
        </TabPanel>
      </div>
    </Layout>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}
