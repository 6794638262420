import React, { useState, useEffect } from "react";
import { getLocalData } from "utils/storage";

const AppContext = React.createContext(null);

/**
 * This hook can only be used by components under the `AppContext` component. Otherwise it will throw.
 */
export const useAppContext = () => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error("Component rendered outside the provider tree");
  }

  return context;
};

/**
 * Component used to render components that depend on AppContext being available. These components can then
 * `useAppContext` safely to get stuff without having to null check it.
 */
export const AppState = (props) => {
  const [state, set_state] = useState(false);

  useEffect(() => {
    const _user = getLocalData("user");
    if (_user) {
      set_state({user: _user, ...state})
    } else {
      set_state({user: null, ...state});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setState = (key, value) => {
    setState({...state, [key]: value});
  }

  return (
    <AppContext.Provider value={{state, setState}}>
      {props.children}
    </AppContext.Provider>
  );
};
