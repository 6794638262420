import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// mui
import { ThemeProvider } from "@mui/material";
import { theme } from "utils/theme";

// contexts
import { AppState } from "contexts/appContext";
import { Datatables } from "contexts/datatable";
import { Modals } from "contexts/modals";
import { Toasts } from "contexts/toasts";

// pages
import Login from "pages/login";
import Dashboard from "./pages/dashboard/index";
import Users from "./pages/users/index";
import AdminProfile from "./pages/adminProfile/index";
import Profile from "./pages/users/profile/index";
import SubscriptionPackages from "pages/subscriptionPackages";
import NotFound from "pages/404";
import Chats from "pages/chats";
import Chat from "pages/chats/chat";
import ScrollToTop from "utils/scrollToTop";
import store from "store/store";
import { Provider } from "react-redux";
import ChaperoneChats from "pages/chaperoneChat";
import ChaperoneChat from "pages/chaperoneChat/chat";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <AppState>
            <Toasts>
              <Modals>
                <Datatables>
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/admin-profile" element={<AdminProfile />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/:userId" element={<Profile />} />
                    <Route
                      path="/subscription-packages"
                      element={<SubscriptionPackages />}
                    />

                    <Route path="/:supportType/chats" element={<Chats />} />
                    <Route
                      path="/:supportType/chat/:recipientId/:roomId"
                      element={<Chat />}
                    />

                    <Route path="/chaperoneChats" element={<ChaperoneChats />} />
                    <Route
                      path="/chaperoneChats/:roomId"
                      element={<ChaperoneChat />}
                    />

                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Datatables>
              </Modals>
            </Toasts>
          </AppState>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}
