import React, { useState, useEffect } from "react";
import { COLORS } from "constants/colors";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useToastsContext } from "contexts/toasts";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { addSubscriptionPackage } from "apis";
import { addPackage } from "store/subscriptionPackagesSlice";
import { useDispatch } from "react-redux";

export default function AddSubscriptionPackage({ open, handleClose }) {
  const dispatch = useDispatch();
  const { toggleToast } = useToastsContext();
  const [_open, set_open] = useState(false);
  const [spackage, setSPackage] = useState({
    backtrack: 1,
    super_swipe: 1,
    unlimited_right_zzwipes: 1,
    advance_filters: 1,
    active: 1
  });
  const [loading, setLoading] = useState();

  const addNewPackage = async () => {
    setLoading(true);
    await addSubscriptionPackage(spackage)
      .then((res) => {
        console.log(res);
        if(res?.data?.status === "fail") {
          toggleToast({
            type: "warning",
            msg: res?.data?.message,
          });
        } else {
          toggleToast({
            type: "success",
            msg: "Successfully Added Subscription Package.",
          });
          dispatch(addPackage({...spackage, id: res.data.result.insertId}));
          set_open(false);
          setSPackage({});
          handleClose();
        }
        
      })
      .catch((err) => {
        console.log(err);
        toggleToast({
          type: "error",
          msg: "Error Adding Subscription Package.",
        });
      });
    setLoading(false);
  };

  useEffect(() => {
    set_open(open);
  }, [open]);

  console.log(spackage);

  return (
    <>
      {open && (
        <Dialog
          open={_open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <div className="flex justify-between items-center mr-2 mt-2">
            <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
              Add Subscription Package
            </DialogTitle>
            <div className="">
              <IconButton
                disabled={loading}
                sx={{ color: COLORS.primary, fontSize: "1.1rem" }}
                onClick={() => handleClose()}>
                <HighlightOffIcon sx={{ fontSize: "2rem" }} />
              </IconButton>
            </div>
          </div>
          <DialogContent sx={{ padding: "1rem" }}>
            <div className="min-w-[250px] sm:w-[400px] flex flex-col gap-5">
              <TextField
                label="Package Name"
                onChange={(e) =>
                  setSPackage({ ...spackage, package_name: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="Store Id"
                onChange={(e) =>
                  setSPackage({ ...spackage, storeId: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="Days"
                type="number"
                onChange={(e) =>
                  setSPackage({ ...spackage, days: parseInt(e.target.value) })
                }
                fullWidth
              />
              <TextField
                label="Duration"
                onChange={(e) =>
                  setSPackage({ ...spackage, duration: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="Package Description"
                rows="3"
                onChange={(e) =>
                  setSPackage({ ...spackage, description: e.target.value })
                }
                multiline
                fullWidth
              />
            </div>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={() => addNewPackage()}>
              Add Package
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
