const { createSlice } = require("@reduxjs/toolkit");

const userSlice = createSlice({
  name: "users",
  initialState: {
    verified: null,
    unverified: null,
  },
  reducers: {
    setUsers(state, action) {
      if (action.payload.type === "verified") {
        state.verified = action.payload.users;
      } else if (action.payload.type === "unverified") {
        state.unverified = action.payload.users;
      }
    },
    deleteUser(state, action) {
      console.log(action.payload)
      if (action.payload.is_verified == 1) {
        state.verified = state.verified.filter(
          (item) => item.userId !== action.payload.userId
        );
      } else if (action.payload.is_verified == 0) {
        state.unverified = state.unverified.filter(
          (item) => item.userId !== action.payload.userId
        );
      }
    },
    updateUser(state, action) {
      if (action.payload.is_verified == 1) {
        const index = state.verified.findIndex(
          (item) => item.id === action.payload.id
        );
        state.verified[index] = { ...state.verified[index], ...action.payload };
      } else if (action.payload.is_verified == 0) {
        const index = state.unverified.findIndex(
          (item) => item.id === action.payload.id
        );
        state.unverified[index] = {
          ...state.unverified[index],
          ...action.payload,
        };
      }
    },
    verifyUser(state, action) {
      const index = state.unverified.findIndex(item => item.userId === action.payload.userId);
      if (index !== -1) {
        const verifiedUser = state.unverified.splice(index, 1)[0];
        state.verified.push(verifiedUser);
      }
    },
    addUser(state, action) {
      if (action.payload.is_verified == 1) {
        state.verified.push(action.payload);
      } else if (action.payload.is_verified == 0) {
        state.unverified.push(action.payload);
      }
    },
  },
});

export const { setUsers, deleteUser, addUser, updateUser, verifyUser } = userSlice.actions;
export default userSlice.reducer;

// Thunks
// export const fetchProducts = createAsyncThunk(
//   "products/fetch",
//   async (endPoint) => {
//     const res = await fetch(endPoint);
//     const data = await res.json();
//     return data;
//   }
// );

// export function fetchProducts() {
//     return async function fetchProductThunk(dispatch, getState) {
//         dispatch(setStatus(STATUSES.LOADING));
//         try {qw
//             const res = await fetch('https://fakestoreapi.com/products');
//             const data = await res.json();
//             dispatch(setProducts(data));
//             dispatch(setStatus(STATUSES.IDLE));
//         } catch (err) {
//             console.log(err);
//             dispatch(setStatus(STATUSES.ERROR));
//         }
//     };
// }
