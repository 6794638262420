import React, { useState, useEffect } from "react";
import { COLORS } from "constants/colors";
import { useToastsContext } from "contexts/toasts";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { updateSubscriptionPackageStatus } from "apis";
import { updatePackage } from "store/subscriptionPackagesSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/index";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export default function ChangeSubscriptionPackageStatus({
  open,
  handleClose,
  payload: id,
}) {
  const dispatch = useDispatch();
  const { packages } = useSelector((state) => state.subscriptionPackages);
  const { toggleToast } = useToastsContext();
  const [_open, set_open] = useState(false);
  const [spackage, setSPackage] = useState();
  const [loading, setLoading] = useState();

  const handleChangePackageStatus = async () => {
    setLoading(true);
    var status = spackage.active;
    
    if(status == 0) {
      status = 1
    } else if (status == 1) {
      status = 0
    }

    await updateSubscriptionPackageStatus({ id: spackage.id, status: status})
      .then((res) => {
        console.log(res);
        if (res?.data?.status === "fail") {
          toggleToast({
            type: "warning",
            msg: res?.data?.message,
          });
        } else {
          dispatch(updatePackage({...spackage, active: status}));
          setSPackage({...spackage, active: status})
          toggleToast({
            type: "success",
            msg: `Successfully ${status == 0 ? "Active" : "Deactive"} subscription package.`,
          });
          
          set_open(false);
          setSPackage({});
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
        toggleToast({
          type: "error",
          msg: err.response.data.message || `Error ${spackage.status == 0 ? "Activating" : "Deactivating"} subscription package.`,
        });
      });
  
    setLoading(false);
  };
  
  console.log(spackage)

  useEffect(() => {
    if (id) {
      const itemss = packages.filter((item) => item.id === id);
      setSPackage(itemss[0]);
    }
  }, [id]);

  useEffect(() => {
    set_open(open);
  }, [open]);

  // console.log(spackage);

  return (
    <>
      {open && (
        <Dialog
          open={_open}
          onClose={() => handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          {spackage ? (
            <>
              <DialogContent sx={{ padding: "1rem", textAlign: "center" }}>
                <div className="min-w-[250px] sm:w-[400px] flex flex-col gap-5">
                  <div className="">
                    <WarningAmberIcon
                      sx={{ fontSize: "3rem", color: COLORS.danger }}
                    />
                  </div>
                  <Typography variant="h4">Are you sure?</Typography>

                  <Typography sx={{ opacity: "0.7" }}>
                    {`Are you Sure to ${spackage.active == 1 ? "Deactivate": "Activate"} "${spackage.package_name}" package!`}
                  </Typography>
                </div>
              </DialogContent>
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  onClick={() => handleClose()}
                  size="large">
                  Cancel
                </Button>
                <LoadingButton
                  size="large"
                  sx={{ margin: "8px" }}
                  variant="contained"
                  loading={loading}
                  onClick={() => handleChangePackageStatus()}>
                  {spackage.active == 1 ? "Deactive": "Active"}
                </LoadingButton>
              </DialogActions>
            </>
          ) : (
            <Loader />
          )}
        </Dialog>
      )}
    </>
  );
}


    // if(spackage.active == 0) {
    //   setSPackage({...spackage, active: 1})
    //   dispatch(updatePackage({...spackage, active: 1}));
    // } else if (spackage.active == 1) {
    //   setSPackage({...spackage, active: 0})
    //   dispatch(updatePackage({...spackage, active: 0}));
    // }