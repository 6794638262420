import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Layout from "fragments/layout/layout";
import { Avatar, TextField, Typography, IconButton } from "@mui/material";
import { COLORS } from "constants/colors";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MUIDataTable from "mui-datatables";
import { getUserDetails, getUserMeta } from "apis";
import { useToastsContext } from "contexts/toasts";
import Loader from "components/loader";
import { convertJsonToArray } from "utils";
import { useModalsContext } from "contexts/modals";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowBool from "components/showBool";
import NoResult from "components/noResult";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TourIcon from '@mui/icons-material/Tour';

export default function Profile() {
  const params = useParams();
  const { userId } = params;
  const { toggleToast } = useToastsContext();
  const { toggleImageZoomModal } = useModalsContext();

  const [userDetails, setUserDetails] = useState();
  const [userMeta, setUserMeta] = useState();

  // Subscription Table
  const columnsForSubscription = [
    {
      name: "",
      label: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, update) => {
          let rowIndex = Number(tableMeta.rowIndex) + 1;
          return <span>{rowIndex}</span>;
        },
      },
    },
    {
      name: "package_name",
      label: "Package name",
      options: {
        customBodyRender: (name) => {
          return <Typography>{name}</Typography>;
        },
      },
    },
    {
      name: "storeId",
      label: "Store Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "days",
      label: "Days",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "super_swipe",
      label: "Super Swipe",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} />;
        },
      },
    },
    {
      name: "advance_filter",
      label: "Advance Filters",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} />;
        },
      },
    },
    {
      name: "backtrack",
      label: "Backtrack",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} />;
        },
      },
    },
    {
      name: "unlimited_right_zzwipes",
      label: "Unlimited zzwipes",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} />;
        },
      },
    },
    {
      name: "super_swipe",
      label: "Super Swipe",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} />;
        },
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (bool) => {
          return <ShowBool condition={bool} type="status" />;
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (dateTime) => {
          return <Typography>{/* {convertDateTime(dateTime)} */}</Typography>;
        },
      },
    },
    {
      name: "userId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (userId) => {
          return (
            <Link to={`/users/${userId}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          );
        },
      },
    },
  ];

  const optionsForSubscription = {
    onRowClick: (user) => {
      console.log(user);
      // const id = user[0];
      // navigate(`/users/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "400px",
    rowsPerPageOptions: [10, 50, 100],
    elevation: 0,
    filter: false,
  };

  const columnsForFavourites = [
    {
      name: "",
      label: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, update) => {
          let rowIndex = Number(tableMeta.rowIndex) + 1;
          return <span>{rowIndex}</span>;
        },
      },
    },
    {
      name: "image",
      label: "User's Profile Image",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (image) => {
          return (
            <Avatar
              onClick={(image) =>
                toggleImageZoomModal({
                  modalTitle: "User's Profile Image",
                  img: image.target.currentSrc,
                })
              }
              variant="rounded"
              src={image}
              sx={{ width: "3rem", height: "3rem", cursor: "pointer" }}
            />
          );
        },
      },
    },
    {
      name: "first_name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (userId) => {
          return (
            <Link to={`/users/${userId}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          );
        },
      },
    },
  ];

  const optionsForFavourites = {
    onRowClick: (user) => {
      console.log(user);
      // const id = user[0];
      // navigate(`/users/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "400px",
    rowsPerPageOptions: [10, 50, 100],
    elevation: 0,
    filter: false,
  };

  const get_user_details = async () => {
    await getUserDetails(userId)
      .then((res) => {
        console.log(res)
        const parsedBasicQuestions = JSON.parse(
          res.data.result.userDetails.basic_question_responses
        );
        const parsedAdvancedQuestions = JSON.parse(
          res.data.result.userDetails.advance_question_responses
        );
        setUserDetails({
          ...res.data.result.userDetails,
          basic_question_responses: convertJsonToArray(parsedBasicQuestions),
          advance_question_responses: convertJsonToArray(
            parsedAdvancedQuestions
          ),
        });
      })
      .catch((err) => {
        console.log(err);
        toggleToast({
          msg: err.response
            ? err.response.data.message
            : "Error Fetching Data, Please try connecting with IT department.",
          type: "error",
        });
      });
  };

  const get_user_meta = async () => {
    await getUserMeta(userId)
      .then((res) => {
        setUserMeta(res.data.result);
      })
      .catch((err) => {
        console.log(err);
        toggleToast({
          msg: err.response
            ? err.response.data.message
            : "Error Fetching Data, Please try connecting with IT department.",
          type: "error",
        });
      });
  };

  useEffect(() => {
    get_user_details();
    get_user_meta();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  console.log(userMeta);
  console.log(userDetails);

  return (
    <Layout title="User Profile">
      <div className="m-5">
        {userDetails && userMeta ? (
          <>
            {/* profile Image & likes */}
            <div className="flex gap-5 flex-col lg:flex-row">
              <div className="flex flex-col min-w-[250px] items-center gap-4 gap-5 p-5 shadow-1 rounded-2xl">
                <div className="border-solid border-2 border-gray_light rounded-full p-1 w-fit">
                  <div className="border-solid border-4 border-gray_light rounded-full">
                    <Avatar
                      sx={{
                        width: "130px",
                        height: "130px",
                        backgroundColor: COLORS.primary,
                        margin: "5px",
                      }}
                      src={userDetails.image}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center  gap-2  w-fit">
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: COLORS.primary,
                      margin: 0,
                      padding: 0,
                    }}>
                    {userDetails.first_name} {userDetails.last_name}
                  </Typography>
                  <Typography className="opacity-50 italic">
                    {userDetails.email}
                  </Typography>
                </div>
              </div>
              <div className="flex gap-5 w-full flex-col sm:flex-row ">
                <div className="grid sm:w-2/3 sm:grid-cols-2 grid-cols-2 w-full gap-5 shadow-1 p-5 rounded-2xl">
                  {userMeta.counts.filter(count => count.action === "dislike")
                    .map((item, i) => (
                      <div
                        className="flex flex-col w shadow-minor rounded-lg h-fit items-center p-5  rounded-2xl"
                        key={i}>
                        <ThumbDownIcon
                          sx={{ fontSize: "2rem", color: COLORS.yellow }}
                        />
                        <Typography variant="h5">{item.count}</Typography>
                        <Typography variant="caption1" sx={{ opacity: "0.4" }}>
                          Dislike
                        </Typography>
                      </div>
                    ))}
                    {userMeta.counts.filter(count => count.action === "like")
                    .map((item, i) => (
                      <div
                        className="flex flex-col w shadow-minor rounded-lg h-fit items-center p-5  rounded-2xl"
                        key={i}>
                        <ThumbUpIcon
                          sx={{ fontSize: "2rem", color: COLORS.yellow }}
                        />
                        <Typography variant="h5">{item.count}</Typography>
                        <Typography variant="caption1" sx={{ opacity: "0.4" }}>
                          Like
                        </Typography>
                      </div>
                    ))}
                    {userMeta.counts.filter(count => count.action === "favourite")
                    .map((item, i) => (
                      <div
                        className="flex flex-col w shadow-minor rounded-lg h-fit items-center p-5  rounded-2xl"
                        key={i}>
                        <FavoriteIcon
                          sx={{ fontSize: "2rem", color: COLORS.yellow }}
                        />
                        <Typography variant="h5">{item.count}</Typography>
                        <Typography variant="caption1" sx={{ opacity: "0.4" }}>
                        Favourite
                        </Typography>
                      </div>
                    ))}
                    {userMeta.counts.filter(count => count.action === "visit")
                    .map((item, i) => (
                      <div
                        className="flex flex-col w shadow-minor rounded-lg h-fit items-center p-5  rounded-2xl"
                        key={i}>
                        <TourIcon
                          sx={{ fontSize: "2rem", color: COLORS.yellow }}
                        />
                        <Typography variant="h5">{item.count}</Typography>
                        <Typography variant="caption1" sx={{ opacity: "0.4" }}>
                        Visit
                        </Typography>
                      </div>
                    ))}
                </div>
                <div className="grid sm:w-1/3 gap-5 grid-cols-2 sm:grid-cols-1 shadow-1 p-5 rounded-2xl">
                  <div className={`flex flex-col ${userDetails.is_verified === 1 ? "bg-green" : "bg-danger"} text-white items-center justify-center shadow-minor rounded-lg items-center p-5 rounded-2xl`}>
                    {userDetails.is_verified === 1 ? "Verified" : "Unverified"}
                  </div>
                  <div className={`flex flex-col ${userDetails.is_banned === 1 ? "bg-danger" : "bg-green"} text-white items-center justify-center shadow-minor rounded-lg items-center p-5 rounded-2xl`}>
                    {userDetails.is_banned === 1 ? "Banned" : "Unbanned"}
                  </div>
                  
                  {/* {Array(2)
                    .fill()
                    .map((item, i) => (
                      <div
                        className="flex flex-col shadow-minor rounded-lg h-fit items-center p-5  rounded-2xl"
                        key={i}>
                        <VisibilityOffIcon
                          sx={{ fontSize: "2rem", color: COLORS.yellow }}
                        />
                        <Typography variant="h5">0</Typography>
                        <Typography variant="caption1" sx={{ opacity: "0.4" }}>
                          Passed
                        </Typography>
                      </div>
                    ))} */}
                </div>
              </div>
            </div>

            {/* Profile Info */}
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 shadow-1 px-5 py-7 my-5 gap-10 rounded-2xl">
              <TextField
                disabled
                label="Email"
                variant="standard"
                fullWidth
                value={userDetails.email}
              />
              <TextField
                disabled
                label="Mobile"
                variant="standard"
                value={userDetails.phone}
                fullWidth
              />
              <TextField
                disabled
                label="Birth Day"
                variant="standard"
                value={userDetails.birthdate}
                fullWidth
              />
              <TextField
                disabled
                label="Instagram"
                variant="standard"
                value={userDetails.insta_profile || userDetails.insta_name}
                fullWidth
              />
              <TextField
                disabled
                label="Gender"
                variant="standard"
                value={userDetails.gender}
                fullWidth
              />
              <TextField
                disabled
                label="Short Bio"
                variant="standard"
                value={userDetails.bio}
                fullWidth
              />
              <TextField
                disabled
                label="Age"
                variant="standard"
                value={userDetails.age}
                fullWidth
              />
              <TextField
                disabled
                label="Interests"
                variant="standard"
                value={userDetails.interest}
                fullWidth
              />
            </div>

            {/* Questions */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div className="shadow-1 rounded-2xl p-5 max-h-[500px] min-h-[300px] overflow-y-scroll">
                <Typography variant="h5">Basic Questions</Typography>
                {userDetails.basic_question_responses.length > 0 ? (
                  <>
                    {userDetails.basic_question_responses.map((question, i) => (
                      <div className="question mt-3">
                        <div className="grid grid-cols-[100px_1fr]">
                          <Typography
                            sx={{ fontWeight: "bold", color: COLORS.yellow }}>
                            Question:{" "}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "bold", color: COLORS.yellow }}>
                            {question.question}
                          </Typography>
                        </div>
                        <div className="grid grid-cols-[100px_1fr]">
                          <Typography
                            sx={{ fontWeight: "bold", color: COLORS.gray }}>
                            Answer:{" "}
                          </Typography>
                          <Typography sx={{ color: COLORS.gray }}>
                            {question.answer}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <NoResult text="No Basic Questions Found." />
                )}
              </div>
              <div className="shadow-1 rounded-2xl p-5 max-h-[500px] min-h-[300px] overflow-y-scroll">
                <Typography variant="h5">Advance Questions</Typography>
                {userDetails.advance_question_responses.length > 0 ? (
                  <>
                    {userDetails.advance_question_responses.map(
                      (question, i) => (
                        <div className="question mt-3">
                          <div className="grid grid-cols-[100px_1fr]">
                            <Typography
                              sx={{ fontWeight: "bold", color: COLORS.yellow }}>
                              Question:{" "}
                            </Typography>
                            <Typography
                              sx={{ fontWeight: "bold", color: COLORS.yellow }}>
                              {question.question}
                            </Typography>
                          </div>
                          <div className="grid grid-cols-[100px_1fr]">
                            <Typography
                              sx={{ fontWeight: "bold", color: COLORS.gray }}>
                              Answer:{" "}
                            </Typography>
                            <Typography sx={{ color: COLORS.gray }}>
                              {question.answer}
                            </Typography>
                          </div>
                        </div>
                      )
                    )}
                  </>
                ) : (
                  <NoResult text="No Advance Questions Found." />
                )}
              </div>
            </div>

            {/* Subscription History */}
            {/* <div className="shadow-1 px -5 pt-2 pb-5 my-5 gap-10 rounded-2xl">
              <div className="h-400 mt-5">
                {userMeta?.subHistory && (
                  <MUIDataTable
                    title={"Subscription History"}
                    data={userMeta?.subHistory}
                    columns={columnsForSubscription}
                    options={optionsForSubscription}
                  />
                )}
              </div>
            </div> */}

            {/* count details */}
            <div className="grid xl:grid-cols-2 md:grid-cols-1 gap-5 mt-[1rem]">
              <div className="shadow-1 py-2 gap-10 rounded-2xl">
                <div className="h-400">
                  {userMeta?.favourite && (
                    <MUIDataTable
                      title={"Favourites"}
                      data={userMeta?.favourite}
                      columns={columnsForFavourites}
                      options={optionsForFavourites}
                    />
                  )}
                </div>
              </div>

              <div className="shadow-1  py-2 gap-10 rounded-2xl">
                <div className="h-400 ">
                  {userMeta?.block && (
                    <MUIDataTable
                      title={"Blocks"}
                      data={userMeta?.block}
                      columns={columnsForFavourites}
                      options={optionsForFavourites}
                    />
                  )}
                </div>
              </div>

              <div className="shadow-1  py-2 gap-10 rounded-2xl">
                <div className="h-400">
                  {userMeta?.like && (
                    <MUIDataTable
                      title={"Likes"}
                      data={userMeta?.like}
                      columns={columnsForFavourites}
                      options={optionsForFavourites}
                    />
                  )}
                </div>
              </div>

              <div className="shadow-1  py-2 gap-10 rounded-2xl">
                <div className="h-400">
                  {userMeta?.dislike && (
                    <MUIDataTable
                      title={"Dislikes"}
                      data={userMeta?.dislike}
                      columns={columnsForFavourites}
                      options={optionsForFavourites}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </Layout>
  );
}


