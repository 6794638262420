import Layout from "fragments/layout/layout";
import React from "react";
import {
  Avatar,
  FormControl,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { COLORS } from "constants/colors";

export default function AdminProfile() {
  return (
    <Layout title="Admin Profile">
      <div className="m-5">
        <div className="flex flex-col sm:flex-row items-center  sm:items-start gap-4 sm:gap-10 p-5 py-10 sm:py-5 shadow-1 rounded-2xl">
          <div className="border-solid border-2 border-gray_light rounded-full p-1 w-fit">
            <div className="border-solid border-4 border-gray_light rounded-full">
              <Avatar
                sx={{
                  width: "130px",
                  height: "130px",
                  backgroundColor: COLORS.primary,
                  margin: "5px",
                }}
              />
            </div>
          </div>
          <div className="flex flex-col items-center sm:items-start gap-2 sm:mt-5 w-fit">
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: COLORS.primary,
                margin: 0,
                padding: 0,
              }}>
              Super Admin
            </Typography>
            <Typography className="opacity-50 italic">
              admin@gmail.com
            </Typography>
            <Typography
              className="opacity-80 rounded px-2 w-fit"
              variant="caption"
              sx={{ background: COLORS.yellow, color: COLORS.dark }}>
              SUPER ADMIN
            </Typography>
          </div>
        </div>
        <div className="grid grid-cols-1  md:grid-cols-2 gap-5 mt-5">
          <div className="p-5 shadow-1 rounded-2xl  flex flex-col gap-4">
            <Typography variant="h5">Admin Info</Typography>
            <FormControl fullWidth className="flex flex-cols gap-5">
              <TextField
                InputProps={{
                  style: { borderRadius: 10 },
                }}
                label="Full name"
                variant="standard"
                value="Super Admin"
                fullWidth
              />
              <TextField
                InputProps={{
                  style: { borderRadius: 10 },
                }}
                label="Email"
                variant="standard"
                value="super.admin@gmail.com"
                disabled
                fullWidth
              />
              <TextField
                InputProps={{
                  style: { borderRadius: 10 },
                }}
                label="Phone"
                variant="standard"
                value="+1 3457467345"
                disabled
                fullWidth
              />
              <Button variant="contained">Update</Button>
            </FormControl>
          </div>
          <div className="p-5 shadow-1 rounded-2xl  flex flex-col gap-4">
            <Typography variant="h5">Change Password</Typography>
            <FormControl fullWidth className="flex flex-cols gap-5">
              <TextField
                InputProps={{
                  style: { borderRadius: 10 },
                }}
                label="Current Password"
                variant="standard"
                fullWidth
                type="password"
              />
              <TextField
                InputProps={{
                  style: { borderRadius: 10 },
                }}
                label="New Password"
                variant="standard"
                fullWidth
                type="password"
              />
              <TextField
                InputProps={{
                  style: { borderRadius: 10 },
                }}
                label="Re-Enter New Password"
                variant="standard"
                fullWidth
                type="password"
              />
              <Button variant="contained">Update</Button>
            </FormControl>
          </div>
        </div>
      </div>
    </Layout>
  );
}
