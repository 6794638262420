import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import subscriptionPackageReducer from "./subscriptionPackagesSlice";

const store = configureStore({
  reducer: {
    users: userReducer,
    subscriptionPackages: subscriptionPackageReducer,
  },
});

export default store;