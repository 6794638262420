import { createTheme } from "@mui/material/styles";
import { COLORS } from "constants/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.yellow,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // fontWeight: 500,
          borderRadius: "10px",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `10px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          // color: theme.colors?.textDark,
          // padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: COLORS.white,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: COLORS.white,
            backgroundColor: COLORS.indigo,
            "&:hover": {
              backgroundColor: COLORS.yellow,
            },
            "& .MuiListItemIcon-root": {
              color: COLORS.indigo,
            },
          },
          "&:hover": {
            backgroundColor: COLORS.yellow,
            color: COLORS.purple,
            "& .MuiListItemIcon-root": {
              color: COLORS.indigo,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: COLORS.indigo,
          minWidth: "36px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          // color: theme.textDark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          // color: theme.textDark,
          "&::placeholder": {
            // color: theme.darkTextSecondary,
            fontSize: "0.875rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // background: bgColor,
          borderRadius: `10px`,
          "& .MuiOutlinedInput-notchedOutline": {
            // borderColor: theme.colors?.grey400,
          },
          "&:hover $notchedOutline": {
            // borderColor: theme.colors?.primaryLight,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          // background: bgColor,
          padding: "15.5px 14px",
          // borderRadius: `${theme?.customization?.borderRadius}px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          // borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            // color: theme.colors?.grey300,
          },
        },
        mark: {
          // backgroundColor: theme.paper,
          width: "4px",
        },
        valueLabel: {
          // color: theme?.colors?.primaryLight,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          // borderColor: theme.divider,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          // color: theme.colors?.primaryDark,
          // background: theme.colors?.primary200,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // color: theme.paper,
          // background: theme.colors?.grey700,
        },
      },
    },
  },
});
