const { createSlice } = require("@reduxjs/toolkit");

const subscriptionPackageSlice = createSlice({
  name: "subscription_packages",
  initialState: {
    packages: null,
  },
  reducers: {
    setPackages(state, action) {
      state.packages = action.payload;
    },
    deletePackage(state, action) {
      state.packages = state.packages.filter((item) => item.id !== action.payload.id);
    },
    updatePackage(state, action) {
      const index = state.packages.findIndex((item) => item.id === action.payload.id);
      state.packages[index] = { ...state.packages[index], ...action.payload };
    },
    addPackage(state, action) {
      state.packages.push(action.payload);
    },
  },
});

export const { setPackages, deletePackage, addPackage, updatePackage } =
  subscriptionPackageSlice.actions;
export default subscriptionPackageSlice.reducer;

// Thunks
// export const fetchProducts = createAsyncThunk(
//   "products/fetch",
//   async (endPoint) => {
//     const res = await fetch(endPoint);
//     const data = await res.json();
//     return data;
//   }
// );

// export function fetchProducts() {
//     return async function fetchProductThunk(dispatch, getState) {
//         dispatch(setStatus(STATUSES.LOADING));
//         try {qw
//             const res = await fetch('https://fakestoreapi.com/products');
//             const data = await res.json();
//             dispatch(setProducts(data));
//             dispatch(setStatus(STATUSES.IDLE));
//         } catch (err) {
//             console.log(err);
//             dispatch(setStatus(STATUSES.ERROR));
//         }
//     };
// }
