import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { COLORS } from "constants/colors";

export default function ShowBool({condition, type}) {
  return (
    <div>
      {condition == 1 ? (
        <div className="w-fit">
          {type === "status" ? (
            <div className="bg-green p-2 rounded-lg text-white">
              Active
            </div>
          ):(
            <CheckIcon sx={{color: COLORS.green}} />
          )}
        </div>
      ):(
        <div className="w-fit">
          {type === "status" ? (
            <div className="bg-danger p-2 rounded-lg text-white">
              Deactive
            </div>
          ):(
            <DoNotDisturbIcon sx={{color: COLORS.danger}} />
          )}
        </div>
      )}
    </div>
  );
}
