import React, { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

const ToastsContext = React.createContext(null);

/**
 * This hook can only be used by components under the `ToastsContext` component. Otherwise it will throw.
 */
export const useToastsContext = () => {
  const context = React.useContext(ToastsContext);

  if (!context) {
    throw new Error("Component rendered outside the provider tree");
  }

  return context;
};

/**
 * Component used to render components that depend on Toasts being available. These components can then
 * `useToastsContext` safely to get stuff without having to null check it.
 */
export const Toasts = (props) => {
  const [toast, setToast] = useState({
    type: "",
    msg: "",
  });
  const [open, setOpen] = useState(false);

  const toggleToast = (arg) => {
    if (!open) {
      setToast(arg);
      setOpen(true);
    } else {
      setOpen(false);
      setToast({});
    }
  };

  return (
    <ToastsContext.Provider value={{ toggleToast }}>
      {toast.type && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={toast.msg ? true : false}
          autoHideDuration={3000}
          onClose={() => {
            setOpen(false);
            setToast({});
          }}>
          <Alert
            onClose={() => {
              setOpen(false);
              setToast({});
            }}
            severity={toast?.type}
            sx={{ width: "100%" }}>
            {toast.msg}
          </Alert>
        </Snackbar>
      )}
      {props.children}
    </ToastsContext.Provider>
  );
};
