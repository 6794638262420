import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Box,
  Typography,
  Container,
  CssBaseline,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Copyright from "components/copyright/index";
import { validateEmail } from "utils/validations";
import { login } from "apis";
import { useToastsContext } from "contexts/toasts";
import { getLocalData, setLocalData } from "utils/storage";

export default function Login() {
  const navigate = useNavigate();
  const { toggleToast } = useToastsContext();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await login(credentials)
      .then((res) => {
        setLocalData("__l", res.data.result.token);
        setLocalData(
          "user",
          JSON.stringify({
            email: res.data.result.user[0].email,
            id: res.data.result.user[0].id,
          })
        );
        toggleToast({
          msg: "Login successfull",
          type: "success",
        });
        // navigate("/dashboard");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toggleToast({
          msg: "Login Failed",
          type: "error",
        });
      });
      setLoading(false);
  };

  const validateFields = useCallback(() => {
    let valid = true;

    const newErrors = {};

    // if (!validatePassword(credentials.password)) {
    //   valid = false;
    //   newErrors.password = "Password should have minimum of 7 charaters and maximum of 14 characters";
    // }
    if (!validateEmail(credentials.email)) {
      valid = false;
      newErrors.email = "Enter valid email";
    }
    setErrors(newErrors);
    return valid;
  }, [credentials]);

  useEffect(() => {
    validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  useEffect(() => {
    const user = getLocalData("user");
    if(user) {
      navigate('/dashboard');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <img
          src="/amirazz.png"
          style={{ width: "50px", margin: "1rem 0 " }}
          alt=""
        />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={errors.email ? true : false}
            helperText={errors.email}
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            autoComplete="current-password"
          />
          <LoadingButton
            loading={loading}
            disabled={
              errors.email ||
              errors.password ||
              credentials.email.length === 0 ||
              credentials.password.length < 4
            }
            fullWidth
            variant="contained"
            size="large"
            onClick={() => handleSubmit()}
            sx={{ mt: 3, mb: 2 }}>
            Sign In
          </LoadingButton>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
