import axios from "axios";
import { api_url } from "constants";
import { getLocalData } from "utils/storage";

// const api_url = "http://192.168.100.64:3001/v1";

let headers = {
  // Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${getLocalData("__l")}`,
  // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInJvbGUiOiJhZG1pbiIsImlhdCI6MTY4NTI5NzE0MH0.tfSKCQ4iyGLpT_PlVs36FfUdxJNIeK7UavReQrpCOBM`,
};

export const login = ({ email, password }) => {
  return axios.post(`${api_url}/admin/login`, {
    email,
    password,
  });
};

export const changePassword = (data) => {
  return axios.post(`${api_url}/admin/changePassword`, data);
};

export const getVerifiedUsers = (page) => {
  return axios.post(
    `${api_url}/admin/getVerifiedUsers?page=${parseInt(page)}`,
    {},
    { headers: headers }
  );
};

export const getUnverifiedUsers = (page) => {
  return axios.post(
    `${api_url}/admin/getVerificationRequest?page=${parseInt(page)}`,
    {},
    { headers: headers }
  );
};

export const getUserDetails = (userId) => {
  return axios.get(
    `${api_url}/admin/getUserDetails?userId=${parseInt(userId)}`,
    { headers: headers }
  );
};

export const getUserMeta = (userId) => {
  return axios.get(`${api_url}/admin/getUserMeta?userId=${parseInt(userId)}`, {
    headers: headers,
  });
};

export const verifyUserApi = ({ userId }) => {
  return axios.patch(
    `${api_url}/admin/verifyUser`,
    { userId },
    { headers: headers }
  );
};

export const changeUserStatus = ({ userId, is_banned }) => {
  return axios.patch(
    `${api_url}/admin/activeOrDeactivate`,
    { userId: userId, is_banned: is_banned },
    { headers: headers }
  );
};

export const deleteUserApi = ({ userId }) => {
  return axios.delete(`${api_url}/admin/deleteUser?userId=${userId}`, {
    headers: headers,
  });
};

export const addSubscriptionPackage = ({
  storeId,
  days,
  package_name,
  description,
  duration,
}) => {
  return axios.post(
    `${api_url}/package/add`,
    { storeId, days, package_name, description, duration },
    { headers: headers }
  );
};

export const getSubscriptionPackages = () => {
  return axios.get(`${api_url}/package`, { headers: headers });
};

export const deleteSubscriptionPackage = ({ id }) => {
  return axios.post(`${api_url}/package/delete`, { id }, { headers: headers });
};

export const updateSubscriptionPackage = ({
  id,
  storeId,
  days,
  package_name,
  description,
  duration,
}) => {
  return axios.patch(
    `${api_url}/package/edit`,
    { id, storeId, days, package_name, description, duration },
    { headers: headers }
  );
};

export const updateSubscriptionPackageStatus = ({ id, status }) => {
  return axios.post(
    `${api_url}/package/status`,
    { id, status },
    { headers: headers }
  );
};
