import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Layout from "fragments/layout/layout";

// firebase
import {
  addDoc,
  doc,
  collection,
  serverTimestamp,
  getDoc,
  query,
  onSnapshot,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import { Avatar, Button, TextField, Typography } from "@mui/material";
import { SendOutlined } from "@mui/icons-material";
import Loader from "components/loader";
import { UpperCaseFirstLetter } from "utils";
import { useModalsContext } from 'contexts/modals';

export default function Chat() {
  const messageEl = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const { toggleImageZoomModal } = useModalsContext();
  const { recipientId, roomId, supportType } = params;
    
  const [userType, setUserType] = useState();
  const [msgs, setMsgs] = useState();
  const [msg, setMsg] = useState();
  const [room, setRoom] = useState();

  const getRoom = async (id) => {
    const docRef = doc(db, 'supportChats', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setRoom(docSnap.data());
    } else {
      return;
    }
  };

  const readMsgs = async () => {
    const dbRef = collection(db, "supportChats", roomId, "messages");
    let arrOfData = [];

    const q = query(dbRef, orderBy("timestamp"));

    const unSub = onSnapshot(q, (snapchot) => {
      arrOfData = [];
      snapchot.docs.map((doc) => {
        arrOfData.push(doc.data());
      });
      setMsgs(arrOfData);
    });
  };

  const sendMsg = async () => {
    const dbRef = collection(db, "supportChats", roomId, "messages");

    if (msg.length > 0) {
      await addDoc(dbRef, {
        from: parseInt(userType),
        to: parseInt(recipientId),
        message: msg,
        timestamp: serverTimestamp(),
        files: []
      })
        .then(async (res) => {
          const dbRef1 = doc(db, "suportChats", roomId);
          await updateDoc(dbRef1, {
            lastMessage: msg,
          })
            .then(async (res1) => {
              setMsg("");
              // if (!msgSent) {
              //   await axios
              //     .post(
              //       `${liveURL}${endPoints.sendNotifications}`,
              //       {
              //         userId: parseInt(id),
              //       },
              //       { headers: headers }
              //     )
              //     .then((res) => {
              //       // console.log(res);
              //       setMsgSent(true);
              //     })
              //     .catch((err) => {
              //       // console.log(err);
              //     });
              // }
            })
            .catch((err) => {
              // console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    scrollToBottom();
  };

  const convertTime = (time) => {
    if (time) {
      const fireBaseTime = new Date(
        time.seconds * 1000 + time.nanoseconds / 1000000
      );
      const date = fireBaseTime.toDateString();
      const atTime = fireBaseTime.toTimeString() 

      return `${atTime.split(":")[0]}:${atTime.split(":")[1]}`;
    } else {
      return "sending...";
    }
  };

  // console.log(roomId);
  console.log(msgs);
  // console.log(room);

  const scrollToBottom = () => {
    messageEl.current?.scrollIntoView();
  };

  const updateSeen = async () => {
    const dbRef1 = doc(db, "supportChats", roomId);
    await updateDoc(dbRef1, {
      unreadCount: 0,
    })
      .then((res1) => {
        // console.log(res1);
        setMsg("");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  scrollToBottom();

  useEffect(() => {
    updateSeen();

    return() => {
      scrollToBottom();
    }

  }, [msgs, roomId]);

  useEffect(() => {
    if (roomId) {
      getRoom(roomId);
      readMsgs();
    }
  }, [roomId]);

  useEffect(() => {
    switch(supportType) {
      case "latina-support":
        setUserType(-1);
        break;
      case "revert-support":
        setUserType(-2);
        break;
      case "expert-support":
        setUserType(-3);
        break;
      default:
        navigate('/not-found');
    }
  }, [supportType])

  console.log(userType);

  return (
    <Layout title={`Chat`}>
      {
        room ? (
          <div className="">
          <div className="flex items-center gap-4 px-4 py-2 border-b border-b-gray_light sticky top-12 z-100 bg-white border-bottom-2">
            <Link to={`/users/${room.user1.id}`} className="flex gap-3">
            <Avatar src={room.user1.image} sx={{width: "3.5rem", height: "3.5rem"}} />
            <div className="flex flex-col">
            <Typography
              variant="h6"
              sx={{
                maxWidth: "16rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}>
              {room.user1.name}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                maxWidth: "16rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                opacity: '0.6'
              }}>
              {UpperCaseFirstLetter(supportType)} User
            </Typography>
            </div>
            </Link>
          </div>
          {msgs ? (
            <>
              <div className="">
                {msgs.length > 0 ? (
                  <div className="">
                    {msgs.map((msg, i) => (
                      <div
                        className={
                          msg.from === userType
                            ? "flex justify-end"
                            : "flex justify-start"
                        }
                        key={i}>
                        <div
                          className={`${msg.from === userType ? "bg-yellow_light" : "bg-grey_light"} mx-4 my-1 rounded-2xl p-4 max-w-[20rem] whitespace-wrap break-words`}>
                          <h5>{msg.name}</h5>
                          <p className="font-xs">{msg.message}</p>
                          {msg.files.length > 0 && (
                            <>
                            {msg.files.map(file => (
                              <div className="cursor-pointer" onClick={() => toggleImageZoomModal({modalTitle: "Message Attachment",
                              img: file,})}>
                                <img src={file} alt="message attachment" className="max-w-[250px]" />
                              </div>
                            ))}
                            </>
                          )}
                          <p
                            style={{
                              fontSize: "12px",
                              width: "100%",
                              textAlign: "right",
                              // opacity: "0.4",
                            }}>
                            {convertTime(msg.timestamp)}
                          </p>
                        </div>
                      </div>
                    ))}
                    <div ref={messageEl} className="p-12"/>
                  </div>
                ) : (
                  <Typography variant="h5" sx={{ color: "red" }}>
                    No Messages yet
                  </Typography>
                )}
              </div>
              <div className="fixed min-[900px]:w-[calc(100%-280px)] w-[100%] bottom-[2px] gap-4 shadow-1 bg-yellow p-4 flex items-center ">
                <TextField
                  placeholder="Message"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  fullWidth
                  multiline
                  maxRows={3}
                  />
                <div className="send__btn">
                  <Button type="submit" onClick={() => sendMsg()} sx={{height: '3.3rem'}} disableElevation variant="contained">
                    <SendOutlined />
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
        ):(
          <Loader />
        )
      }
   
    </Layout>
  );
}
