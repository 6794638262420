import React from "react";
import { Link } from "react-router-dom";

import { Avatar, IconButton, Typography } from "@mui/material";
import { convertDateTime } from "utils";
import { useModalsContext } from "./modals";
import VisibilityIcon from '@mui/icons-material/Visibility';

const DatatablesContext = React.createContext(null);

/**
 * This hook can only be used by components under the `DatatablesContext` component. Otherwise it will throw.
 */
export const useDatatablesContext = () => {
  const context = React.useContext(DatatablesContext);

  if (!context) {
    throw new Error("Component rendered outside the provider tree");
  }

  return context;
};

export const Datatables = (props) => {
  // const navigate = useNavigate();
  const {toggleImageZoomModal} = useModalsContext();

  // User Table
  const columnsForVerifiedUsers = [
    {
      name: "",
      label: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, update) => {
          let rowIndex = Number(tableMeta.rowIndex) + 1;
          return <span>{rowIndex}</span>;
        },
      },
    },
    {
      name: "image",
      label: "User's Profile Image",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (image) => {
          return (
            <Avatar
              onClick={(image) => toggleImageZoomModal({modalTitle: "User's Profile Image", img: image.target.currentSrc})}
              variant="rounded"
              src={image}
              sx={{ width: "3rem", height: "3rem", cursor: 'pointer' }}
            />
          );
        },
      },
    },
    {
      name: "first_name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
        
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "phone",
      label: "User's Phone Number",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "created_at",
      label: "Registered At",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (dateTime) => {
          return (
            <Typography>
              {convertDateTime(dateTime)}
            </Typography>
          );
        },
      },
    },

    {
      name: "gender",
      label: "Gender",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (userId) => {
          return (
            <Link to={`/users/${userId}`}>
            <IconButton>
              <VisibilityIcon />
            </IconButton>
            </Link>
          );
        },
      },
    }

    // {
    //   name: "status",
    //   label: "Account Status",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (status) => {
    //       return (
    //         <Typography
    //           sx={{
    //             color: status === "active" ? "green" : "red",
    //             fontWeight: "bolder",
    //           }}>
    //           {status}
    //         </Typography>
    //       );
    //     },
    //   },
    // },
    ];

    const optionsForVerifiedUsers = {
      onRowClick: (user) => {
        console.log(user);
        // const id = user[1];
        // navigate(`/users/${id}`);
      },
      selectableRowsHideCheckboxes: true,
      selectableRowsHeader: false,
      tableBodyMaxHeight: "500px",
      rowsPerPageOptions: [10, 50, 100],
      elevation: 2,
      filter: false,
    };

    const columnsForUnverifiedUsers = [
      {
        name: "",
        label: "#",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, update) => {
            let rowIndex = Number(tableMeta.rowIndex) + 1;
            return <span>{rowIndex}</span>;
          },
        },
      },
      {
        name: "image",
        label: "User's Profile Image",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (image) => {
            return (
              <Avatar
                onClick={(image) => toggleImageZoomModal({modalTitle: "User's Profile Image", img: image.target.currentSrc})}
                variant="rounded"
                src={image}
                sx={{ width: "3rem", height: "3rem", cursor: 'pointer' }}
              />
            );
          },
        },
      },
      {
        name: "image",
        label: "User's Camera Image",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (image) => {
            return (
              <Avatar
                onClick={(image) => toggleImageZoomModal({modalTitle: "User's Camera Image", img: image.target.currentSrc})}
                variant="rounded"
                src={image}
                sx={{ width: "3rem", height: "3rem", cursor: 'pointer' }}
              />
            );
          },
        },
      },
      {
        name: "first_name",
        label: "User's Name",
        options: {
          filter: true,
          sort: true,
          
        },
      },
      {
        name: "email",
        label: "User's Email ID",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "phone",
        label: "User's Phone Number",
        options: {
          filter: false,
          sort: false,
        },
      },

      {
        name: "created_at",
        label: "Registered At",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (dateTime) => {
            return (
              <Typography>
                {convertDateTime(dateTime)}
              </Typography>
            );
          },
        },
      },

      {
        name: "gender",
        label: "Gender",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "userId",
        label: "Action",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (userId) => {
            return (
              <Link to={`/users/${userId}`}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
              </Link>
            );
          },
        },
      }

      // {
      //   name: "status",
      //   label: "Account Status",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRender: (status) => {
      //       return (
      //         <Typography
      //           sx={{
      //             color: status === "active" ? "green" : "red",
      //             fontWeight: "bolder",
      //           }}>
      //           {status}
      //         </Typography>
      //       );
      //     },
      //   },
      // },
    ];

    const optionsForUnverifiedUsers = {
      onRowClick: (user) => {
        console.log(user);
        // const id = user[1];
        // navigate(`/users/${id}`);
      },
      selectableRowsHideCheckboxes: true,
      selectableRowsHeader: false,
      tableBodyMaxHeight: "500px",
      rowsPerPageOptions: [10, 50, 100],
      elevation: 2,
      filter: false,
    };

  // Subscription Table
  const columnsForSubscription = [
    {
      name: "id",
      label: "Subscription Id",
      options: {
        filter: true,
        sort: true,
        display: "exclude",
      },
    },
    {
      name: "User",
      label: "User's name",
      options: {
        customBodyRender: (name) => {
          return <Typography>{name}</Typography>;
        },
      },
    },
    {
      name: "package",
      label: "Package name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "packageType",
      label: "Package type",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "subscriptionDate",
      label: "Subscription Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "subscriptionEndDate",
      label: "Subscription End Date",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (status) => {
          return (
            <Typography
              sx={{
                color: status === "active" ? "green" : "red",
                fontWeight: "bolder",
              }}>
              {status}
            </Typography>
          );
        },
      },
    },
  ];

  const optionsForSubscription = {
    onRowClick: (user) => {
      console.log(user);
      // const id = user[0];
      // navigate(`/users/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "400px",
    rowsPerPageOptions: [10, 50, 100],
    elevation: 0,
    filter: false,
  };

  // Favourites
  const columnsForFavourites = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "id",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const optionsForFavourites = {
    onRowClick: (user) => {
      console.log(user);
      // const id = user[0];
      // navigate(`/users/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "400px",
    rowsPerPageOptions: [10, 50, 100],
    elevation: 0,
    filter: false,
  };

  return (
    <DatatablesContext.Provider
      value={{
        columnsForVerifiedUsers,
        optionsForVerifiedUsers,
        columnsForUnverifiedUsers,
        optionsForUnverifiedUsers,
        columnsForSubscription,
        optionsForSubscription,
        columnsForFavourites,
        optionsForFavourites,
      }}>
      {props.children}
    </DatatablesContext.Provider>
  );
};
