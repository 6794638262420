import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAUne8IxLP778oVAV4UEqGp8bMJdwTHuOQ",
  authDomain: "amirazz-8907e.firebaseapp.com",
  projectId: "amirazz-8907e",
  storageBucket: "amirazz-8907e.appspot.com",
  messagingSenderId: "135301394980",
  appId: "1:135301394980:web:e247deca00cfc97d81deb1",
  measurementId: "G-LZTSFM0FQD"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const fcm = getMessaging(app);
