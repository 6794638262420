import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Button, Box } from "@mui/material";
import { COLORS } from "constants/colors.js";

import { removeLocalData } from "utils/storage";

export default function Header({ onMenuClick, clicked }) {
  const handleLogout = () => {
    removeLocalData("__l");
    removeLocalData("user");
    window.location.reload();
  };

  return (
    <>
      <header className="header">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div className="menuIcon">
            <IconButton className="menuIcon" onClick={() => onMenuClick()}>
              <MenuIcon sx={{ fontSize: "35px", color: COLORS.primary }} />
            </IconButton>
          </div>
          <div className="header_logo_mobile">
            <img
              src="/amirazz.png"
              alt="amirazz logo"
              style={{ width: "40px", height: "40px", marginLeft: "10px" }}
            />
          </div>
        </Box>
        <div className="mr-2">
          <Link to="/">
            <Button
              variant="contained"
              onClick={() => handleLogout()}
            >
              LogOut
            </Button>
          </Link>
        </div>
      </header>
    </>
  );
}
