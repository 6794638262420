export function validateEmail(email) {
  if (email.length > 0) {
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  } else {
    return true;
  }
}

export function validatePassword(password) {
  if (password.length > 0) {
    const _password = /^[A-Za-z]\w{7,14}$/;
    if (password.match(_password)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function allowSubmit(errorsObj, formData) {
  if (Object.keys(errorsObj).length === 0) {
    for (let key in formData) {
      if (formData[key].length === 0) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}